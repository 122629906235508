import { render, staticRenderFns } from "./support-join-request-popup.html?vue&type=template&id=f5c5f676&scoped=true&"
import script from "./support-join-request-popup.js?vue&type=script&lang=js&"
export * from "./support-join-request-popup.js?vue&type=script&lang=js&"
import style0 from "./support-join-request-popup.scss?vue&type=style&index=0&id=f5c5f676&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5c5f676",
  null
  
)

export default component.exports